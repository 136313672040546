body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  list-style-type: none;
}

a {
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
}

input:focus {
  outline: none;
}

.no-wrap {
  white-space: nowrap;
}

.bold {
  font-weight: 500;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
}
